export const environment = {
  production: true,
  isLive: false,
  AppName: "API Connector",
  multi_API_ENDPOINT: {
     mano_mano: {
      name: "Mano-Mano",
      API_ENDPOINT: "https://cedserver.com/mano-mano/remote/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjQxOTc4NDczNTBhMzU4OTU1MDRjMjQ3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjUzY2NiMWUzNTA0ZDJhNjRkMDdmODAyIn0.g9nxsvS5MSTpkAb8s-AVViXobvM7eYFnOrhUKzGdLBqZR8DMMcK-Bq4ujJSoUmMDhGmeIibHsm8Inr6rCqUT-KdlIY92ihmI1T6js5p2iN-X_5mjxR_I7loGHJmIM-yxUsf_JpjWgP-bXitFdJ0re92xHv8TGNiiGM6HXVTSfFYfsQ0nebYUic2vNpNTLNV_wIVABonV47sHVRBiG9rkN0wNxldkPHUm07kDp3rTDV9REXjXTE58qTpFbVtnO-qeG47x2YAXnVYiKPTwCsUB0D7e5TP6vW5Ux6B8puqIo5OjQv6e8frXX8lcFf-piU98BELT-Pgz0vvYYQmPEjKMFA",
    },
    amazon_magento: {
      name: "Amazon-Magento",
      API_ENDPOINT: "https://cedserver.com/amazon-magento/remote/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjU0MzRhOWNjNGUxYTIyZjAzMGZiYWE3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjU0MzRhYTc0ZDE0NjBhZmZiMGZkNWIyIn0.Z9LPGOXrxvQABPFmhtJL5PzHZuWn-HWLBKOxcDvrB3iEESs3SX-eutk8gLX9Exiu6eQfS_ihCyLVBypCsUQKweNEU0mv53517Yc91f2a0UnnlJFeSHJlRR6Oqo-Zrr2ZIOeWZ8IgWMqLia31Jdgkvq_xct-Go2U-stMT93tjfHvJHnqBYrrvcLnytDQekvSLz6z-CCiW_YmFPDiuus-PjoCbWM1P3GZgMIDU51Yf_StOiaWoIbwNZi3K8jMYfEBAg5JK7AcZOPNPu6mOK-D1NqRcFTPn2kHfy48540-X6TYF3UoSvkliI96Rpg-7Yp4WKqMOtCQd3YkjcP00tesrFw",
    },
    bigcom_cif: {
      name: "Bigcommerce-CIF",
      API_ENDPOINT: "https://cedserver.com/bigcom-cif/remote/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjRjOGQ5YTI5MDliNzI1OGY4MGRkYTE3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjU0Y2IyNTEzMzlkODhkOGZhMDJhOWYyIn0.Ls28kOp4Ct92xnu2UspVQEv1_YxtXJAT2H9wyjURmrGtstC-NpfnBZyObRYtyCWgbQun_cpwKWv43EWFMTSNL9EQ86JrqXR4KeBetjTrsE0Mwh49vdXZJkZgCXLzLLJHIQJydJr4pM9dPdoncCDpRo9M70jeX5Jpd4BBQWk9Ahq6YBApoN3EqqzzSoLltscd3myd3_Xwpz52GjCKpkbRNkdLQUY6QPf8L71Qn9DZiJRLrMiO3b-fW_5BDhrLxkF-uEvf7wophtcemGMNH9HZ7zg_vWraq0J47zFk2AcFCN3cq67ZkNsTYZDbyZNWcEv9su1WxkSZCxxIzwOQG-09ag",
    },
    mirakl_woocommerce: {
      name: "Mirakl_Woocommerce",
      API_ENDPOINT: "https://miraklwoo-api.cedserver.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjViYjQ5NzY2Yzg2NGYxZTU4MDQ4N2E3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjViYzhiZDFhOTQwZGQ2MzljMGM4YzgyIn0.mHA5jH7J5SB1UufffyFm0FUNsXc_ybBdmAJ72-4hXoHtbOd8migo8kmv3LRpC0c3UZsH4yAUugxyJ2USyH7YwIwuLZQ0LMDLWEed8xCzJYpopdyX4h8p0Jew7hZ6AZD0XdBTNk4FS3PinKiit1PEEb6MS9bu3thM--2JDsY5iSnf4Xn-tXOBQO6NbWiCRH4TytsysnNkkcGmuLbLwRyg7SUgphKdn3ut6yMlEEGuE9Bmpueuf8P0jy8qK2WpLqbTVMb-NmuS4pCa7iPcZA3NsI3drQMmSgqIskVkmyivuqG-bIPS4-8o59KxZSqHIQou62V3cliKjzX7nSobhiHPuA",
    }
  },
};
/* Bearer on the last line is App token for server */
